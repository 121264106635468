<template>
  <div>
    <transition name="fade">
      <div
        v-if="showing"
        class="inset-0 z-40 absolute bg-black bg-opacity-25"
      ></div>
    </transition>
    <transition name="scale">
      <div
        v-if="showing"
        class="absolute inset-0 z-40 flex justify-center items-center"
      >
        <div
          class="w-full mx-5 sm:mx-0 sm:w-2/3 md:w-1/2 border border-gray-500 bg-white shadow-2xl rounded-lg z-40 p-10 flex flex-col items-center"
        >
          <h2 class="text-secondary font-bold text-3xl mb-10 leading-none">
            {{ title }}
          </h2>
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
  },
  //   watch: {
  //     showing(value) {
  //       if (value) {
  //         return document.querySelector('body').classList.add('overflow-hidden');
  //       }

  //       document.querySelector('body').classList.remove('overflow-hidden');
  //     }
  //   },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
