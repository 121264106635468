<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="iV Clients" backTo="tools">
      <button
        @click="showingCreateActivationPanel = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Create Activation</span>
      </button>
    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md">
        <div class="w-full flex items-center justify-between mb-5">
          <div class="flex space-x-2">
            <button
              @click="selectTab('all')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'all',
                'hover:bg-gray-200': currentTab != 'all',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
              </svg>
              <span>All Clients</span>
            </button>

            <button
              @click="currentTab = 'search'"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'search',
                'hover:bg-gray-200': currentTab != 'search',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex flex-1 justify-end">
            <input
              type="text"
              class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
              placeholder="Search contacts by name, addresses or people"
              v-model="searchTerm"
              @keydown.enter="searchClients"
            />
            <button
              @click="searchClients"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </div>
        </div>
        <div v-if="viewState == 'Idle'">
          <Table
            v-if="currentTab == 'all'"
            :columns="columns"
            :data="historicActivations"
            :totalRecords="paginationOptions.totalRecords"
            :hasPagination="true"
            :currentPage="paginationOptions.currentPage"
            :totalPages="paginationOptions.totalPages"
            :initialSortOptions="sortOptions"
            @onSortChanged="onTableSortChanged"
            @onPageChanged="onTablePageChanged"
            @onRowClicked="onInfoClicked"
          >
            <template v-slot:is_activated="{ row }">
              <div class="flex justify-center">
                <svg
                  v-if="row.is_activated"
                  class="w-8 h-8 stroke-current text-green-500"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
              </div>
            </template>
            <template v-slot:payment_status="{ row, column }">
              <span
                v-if="column.selector(row) == 'Pending'"
                class="text-orange-600"
                >Pending</span
              >
              <span v-else>{{ column.selector(row) }}</span>
            </template>

            <!-- Set Payment Status -->
            <template v-slot:set_payment_status="{ row }">
              <form
                @submit.prevent="setPaymentStatus($event, row.client_id)"
                class="inline-flex hover:shadow focus-within:shadow rounded border divide-x divide-gray-400 border-gray-400"
              >
                <div class="w-24 relative">
                  <select
                    @click.stop=""
                    class="bg-gray-100 focus:outline-none rounded-l px-2 py-1 pr-8 block appearance-none w-full"
                  >
                    <option
                      :selected="row.metadata.payment_status == 'Invoiced'"
                    >
                      Invoiced
                    </option>
                    <option :selected="row.metadata.payment_status == 'FOC'">
                      FOC
                    </option>
                    <option
                      :selected="row.metadata.payment_status == 'Pending'"
                    >
                      Pending
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
                <button
                  @click.stop=""
                  type="submit"
                  class="bg-gray-100 hover:bg-primary px-2 rounded-r"
                >
                  <svg
                    class="w-5 h-5 stroke-current"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </button>
              </form>
            </template>
            <!-- /Set Payment Status -->

            <!-- View Info -->
            <!-- <template v-slot:view_info="{ row }">
                <button
                  class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md pl-2 py-1"
                  @click="onInfoClicked(row)"
                >
                  <FaqIcon />
                </button>
              </template> -->
            <!-- /View Info -->
          </Table>
          <Table
            v-if="currentTab == 'search'"
            :columns="columns"
            :data="searchResults"
            :totalRecords="searchPaginationOptions.totalRecords"
            :hasPagination="true"
            :currentPage="searchPaginationOptions.currentPage"
            :totalPages="searchPaginationOptions.totalPages"
            :initialSortOptions="sortOptions"
            @onSortChanged="onTableSortChanged"
            @onPageChanged="onTablePageChanged"
            @onRowClicked="onInfoClicked"
          >
            <template v-slot:is_activated="{ row }">
              <div class="flex justify-center">
                <svg
                  v-if="row.is_activated"
                  class="w-8 h-8 stroke-current text-green-500"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
              </div>
            </template>
            <template v-slot:payment_status="{ row, column }">
              <span
                v-if="column.selector(row) == 'Pending'"
                class="text-orange-600"
                >Pending</span
              >
              <span v-else>{{ column.selector(row) }}</span>
            </template>

            <!-- Set Payment Status -->
            <template v-slot:set_payment_status="{ row }">
              <form
                @submit.prevent="setPaymentStatus($event, row.client_id)"
                class="inline-flex hover:shadow focus-within:shadow rounded border divide-x divide-gray-400 border-gray-400"
              >
                <div class="w-24 relative">
                  <select
                    @click.stop=""
                    class="bg-gray-100 focus:outline-none rounded-l px-2 py-1 pr-8 block appearance-none w-full"
                  >
                    <option
                      :selected="row.metadata.payment_status == 'Invoiced'"
                    >
                      Invoiced
                    </option>
                    <option :selected="row.metadata.payment_status == 'FOC'">
                      FOC
                    </option>
                    <option
                      :selected="row.metadata.payment_status == 'Pending'"
                    >
                      Pending
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
                <button
                  @click.stop=""
                  type="submit"
                  class="bg-gray-100 hover:bg-primary px-2 rounded-r"
                >
                  <svg
                    class="w-5 h-5 stroke-current"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </button>
              </form>
            </template>
            <!-- /Set Payment Status -->

            <!-- View Info -->
            <!-- <template v-slot:view_info="{ row }">
                <button
                  class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md pl-2 py-1"
                  @click="onInfoClicked(row)"
                >
                  <FaqIcon />
                </button>
              </template> -->
            <!-- /View Info -->
          </Table>
        </div>
        <div v-show="viewState == 'Loading'">
          <spinner class="mx-auto my-4" :color="'text-black'" :size="8" />
        </div>

        <div v-if="error">
          <div
            class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
          >
            <p>
              Couldn't load work order history {{ error ? `(${error})` : `` }}
            </p>
          </div>
        </div>
      </div>
    </main>
    <portal to="overlay-outlet">
      <alert
        :showing="activationResultAlertShowing"
        title="Ivy Activation Successful"
      >
        <div class="w-full grid grid-cols-2 gap-3">
          <div class="flex flex-col pb-3">
            <h4 class="text-lg font-semibold">Activation Code:</h4>
            <p
              class="text-3xl font-bold bg-primary px-6 py-2 inline-block rounded-lg tracking-wider text-center"
            >
              {{ activationResponse.activation_code }}
            </p>
          </div>
          <div class="flex flex-col pb-3">
            <h4 class="text-lg font-semibold">VTS Number:</h4>
            <p
              class="text-3xl font-bold bg-primary px-6 py-2 inline-block rounded-lg tracking-wider text-center"
            >
              {{ activationResponse.vts_number }}
            </p>
          </div>
          <div class="flex flex-col col-span-2">
            <h4 class="text-lg font-semibold">Agent Name:</h4>
            <p class="border rounded border-gray-400 bg-gray-100 py-2 px-4">
              {{ activationResponse.agent_name }}
            </p>
          </div>
          <div class="flex flex-col col-span-2">
            <h4 class="text-lg font-semibold">Agent Address:</h4>
            <p class="border rounded border-gray-400 bg-gray-100 py-2 px-4">
              {{ formatAddress(activationResponse.agent_address) }}
            </p>
          </div>
          <div class="flex flex-col col-span-2">
            <h4 class="text-lg font-semibold">Client Name:</h4>
            <p class="border rounded border-gray-400 bg-gray-100 py-2 px-4">
              {{ activationResponse.client_name }}
            </p>
          </div>
          <div class="flex flex-col col-span-2">
            <h4 class="text-lg font-semibold">Location Name:</h4>
            <p class="border rounded border-gray-400 bg-gray-100 py-2 px-4">
              {{ activationResponse.location_name }}
            </p>
          </div>
          <div class="flex flex-col col-span-2">
            <h4 class="text-lg font-semibold">Location:</h4>
            <p class="border rounded border-gray-400 bg-gray-100 py-2 px-4">
              {{ formatAddress(activationResponse.location_address) }}
            </p>
          </div>
        </div>

        <div class="flex w-full mt-10">
          <button
            @click="onActivationResponseDone"
            class="w-full flex items-center justify-center bg-blue-200 hover:bg-blue-300 text-secondary py-2 px-4 font-semibold rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
          >
            Done
          </button>
        </div>
      </alert>
    </portal>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingCreateActivationPanel"
        @close="showingCreateActivationPanel = false"
        title="Create SW900V4 Activation"
      >
        <CreateIvyActivation @onActivationComplete="onActivationComplete" />
        <!-- @complete="" -->
      </Panel>
    </portal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import Alert from "@/components/Alert.vue";
import Table from "@/components/Table.vue";
import CreateIvyActivation from "@/components/Tools/IvyActivationCodeGen/CreateIvyActivation.vue";

const FaqIcon = () => import("@/assets/icons/faq-icon.vue");

export default {
  components: {
    PageHeader,
    Notification,
    Spinner,
    Panel,
    Alert,
    Table,
    FaqIcon,
    CreateIvyActivation,
  },
  data() {
    return {
      viewState: "Idle",
      error: null,
      currentTab: "all",
      searchTerm: "",
      searchTermWas: "",
      showingCreateActivationPanel: false,
      activationResultAlertShowing: false,
      historicActivations: [],
      searchResults: [],
      highlightedCodeId: "",
      columns: [
        {
          title: "Customer",
          field: "org_name",
          selector: (row) => row.org_name,
        },
        {
          title: "Agent Name",
          field: "agent_name",
          selector: (row) => row.agent_name,
        },
        {
          title: "Payment Status",
          field: "payment_status",
          selector: (row) => row.metadata.payment_status,
          type: "custom",
        },
        {
          title: "PC Name",
          field: "client_name",
          selector: (row) => row.client_name,
        },
        {
          title: "Date",
          field: "created_at",
          selector: (row) => row.created_at,
          type: "date",
        },
        {
          title: "Activated?",
          field: "is_activated",
          type: "custom",
          sortable: false,
        },
        {
          title: "VTS",
          field: "vts_number",
          selector: (row) => row.vts_number,
        },
        {
          title: "Activation Code",
          field: "activation_code",
          selector: (row) =>
            row.is_activated ? row.activation_code_was : row.activation_code,
        },
        {
          title: "Payment Status",
          field: "set_payment_status",
          type: "custom",
        },
        {
          title: "",
          field: "view_info",
          type: "custom",
          sortable: false,
        },
      ],
      paginationOptions: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
      },
      sortOptions: {
        field: "created_at",
        isAsc: false,
        pageSize: 50,
      },
      searchPaginationOptions: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
      },
      searchSortOptions: {
        field: "created_at",
        isAsc: false,
        pageSize: 50,
      },
      activationResponse: {},
    };
  },
  async mounted() {
    await this.loadActivationHistory();
  },
  methods: {
    selectTab: function (tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      // this.$store.dispatch("setContactSearchTerm", "");
    },
    loadActivationHistory: async function () {
      this.viewState = "Loading";
      try {
        let response = await this.iVClientService.getIvyActivations({
          ...this.paginationOptions,
          ...this.sortOptions,
        });
        response.data.map((activation) => {
          if (!activation.metadata || !activation.metadata.payment_status) {
            activation.metadata = {};
            activation.metadata.payment_status = "Pending";
          }
        });
        this.historicActivations = response.data;
        this.paginationOptions.currentPage = response.page;
        this.paginationOptions.totalPages = response.page_count;
        this.paginationOptions.totalRecords = response.count;
        console.log(this.historicActivations);
        this.viewState = "Idle";
      } catch (error) {
        this.viewState = "Error";
        this.error = error;
      }
    },
    async searchClients() {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      // this.$store.dispatch("setContactSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.viewState = "Loading";

        this.$nextTick(() => {
          this.currentTab = "search";
        });

        let response = await this.iVClientService.searchIvyActivations(
          this.searchTerm,
          {
            ...this.paginationOptions,
            ...this.sortOptions,
          }
        );
        response.data.map((activation) => {
          if (!activation.metadata || !activation.metadata.payment_status) {
            activation.metadata = {};
            activation.metadata.payment_status = "Pending";
          }
        });
        this.searchResults = response.data;
        this.searchPaginationOptions.currentPage = response.page;
        this.searchPaginationOptions.totalPages = response.page_count;
        this.searchPaginationOptions.totalRecords = response.count;

        // let results = await this.ContactService.searchContact(
        //   this.searchTerm,
        //   this.searchPagingParams
        // );

        // this.searchResults = results.data;
        // this.totalSearchResults = results.count;
        // this.searchTermWas = this.searchTerm;
        this.viewState = "Idle";
      } catch (err) {
        console.error(err);
        this.viewState = "Error";
      }
    },
    setPaymentStatus: async function (event, clientId) {
      const status = event.target[0].value;
      if (!status) return;
      await this.iVClientService.setIvyActivationsStatus(clientId, status);
      const row = this.historicActivations.find(
        (activation) => activation.client_id == clientId
      );
      row.metadata.payment_status = status;

      // this.$nextTick(function() {
      //   const form = this.$refs[`payment-status-form-${clientId}`];
      //   form && form.reset();
      // });
      this.$breadstick.notify(
        ({ h, onClose }) => {
          return h(
            Notification,
            {
              props: {
                title: "iV Client updated",
                close: onClose,
              },
            },
            row.agent_name
              ? `${row.agent_name}, ${row.org_name}, ${row.client_name} has been successfully updated`
              : `${row.org_name}, ${row.client_name} has been successfully updated`
          );
        },
        {
          position: "top-right",
        }
      );
    },
    onInfoClicked: function (row) {
      this.$router.push({
        name: "tools-ivy-clients-detail",
        params: { clientId: row.client_id },
      });
    },
    onTableSortChanged: async function (sortOptions) {
      console.log(
        "[IvyActivation] onTableSortChanged: Changed Sort: ",
        sortOptions
      );
      this.currentTab == "all"
        ? (this.sortOptions = sortOptions)
        : (this.searchSortOptions = sortOptions);
      console.log("SORT OPTIONS", sortOptions);
      await this.loadActivationHistory();
    },
    onTablePageChanged: async function (direction) {
      console.log(
        "[IvyActivation] onTablePageChanged: Page changed in the following direction: ",
        direction
      );
      this.currentTab == "all"
        ? (this.paginationOptions.currentPage =
            this.paginationOptions.currentPage + direction)
        : (this.searchPaginationOptions.currentPage =
            this.searchPaginationOptions.currentPage + direction);
      this.currentTab == "all"
        ? await this.loadActivationHistory()
        : this.searchClients();
    },
    formatAddress: function (address) {
      if (!address) return;
      console.log(address);
      let addr1 = address.address1 ? `${address.address1}, ` : "";
      let addr2 = address.address2 ? `${address.address2}, ` : "";
      let addr3 = address.address3 ? `${address.address3}, ` : "";
      let addr4 = address.address4 ? `${address.address4}, ` : "";
      let city = address.city ? `${address.city}, ` : "";
      let county = address.county ? `${address.county}, ` : "";
      let zip = address.zip ? `${address.zip}, ` : "";
      let country = address.country ? `${address.country}` : "";

      return `${addr1}${addr2}${addr3}${addr4}${city}${county}${zip}${country}`;
    },
    onActivationComplete: async function (activationResult) {
      this.activationResponse = activationResult;
      this.showingCreateActivationPanel = false;
      this.activationResultAlertShowing = true;
      await this.loadActivationHistory();
    },
    onActivationResponseDone: function () {
      this.activationResultAlertShowing = false;
      this.activationResponse = {};
    },
  },
};
</script>
