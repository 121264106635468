<template>
  <div class="grid">
    <div class="pb-0 overflow-x-scroll">
      <table class="w-full table-auto">
        <thead class="border-b-2 border-primary">
          <tr class="text-left cursor-pointer select-none">
            <template v-for="(column, i) in columns">
              <th
                @click="sortColumn(column)"
                :class="[
                  { 'hover:bg-gray-200 ': column.sortable != false },
                  'px-3 py-1 rounded-t',
                  column.widthClass,
                ]"
                :key="`th-${i}`"
              >
                <div class="flex justify-between items-center">
                  <span class="mr-2">{{ column.title }}</span>
                  <div
                    v-if="column.sortable != false"
                    class="flex flex-col text-gray-400"
                  >
                    <svg
                      :class="[
                        'h-5 w-5',
                        {
                          'text-green-500':
                            sortOptions.field == column.field &&
                            sortOptions.isAsc,
                        },
                      ]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <svg
                      :class="[
                        'h-5 w-5',
                        {
                          'text-green-500':
                            sortOptions.field == column.field &&
                            !sortOptions.isAsc,
                        },
                      ]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </th>
            </template>
          </tr>
          <tr class="select-none">
            <template v-for="(column, i) in columns">
              <th :key="`f-${i}`">
                <div
                  v-if="
                    filterOptions[column.field] &&
                    filterOptions[column.field].options
                  "
                  class="w-full my-1 inline-block relative"
                >
                  <select
                    v-model="enabledFilters[column.field]"
                    class="bge-input bge-select rounded"
                    @change="
                      filterChanged({
                        column: column.field,
                        value: $event.target.value,
                      })
                    "
                  >
                    <option selected :value="undefined">All</option>
                    <optgroup :label="column.title">
                      <template
                        v-for="(option, i) in filterOptions[column.field]
                          .options"
                      >
                        <option :key="`op-${i}`" :value="option.value">
                          {{ option.label }}
                        </option>
                      </template>
                    </optgroup>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>

                <div
                  v-if="
                    filterOptions[column.field] &&
                    filterOptions[column.field].lookup
                  "
                  class=""
                >
                  <div
                    v-if="filterOptions[column.field].lookup.open"
                    @click="closeLookup(column.field)"
                    class="absolute inset-0 z-50"
                  ></div>
                  <div class="relative">
                    <div class="relative">
                      <input
                        readonly
                        @click="openLookup(column.field)"
                        v-model="cachedLookupLabels[column.field]"
                        class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
                        type="text"
                      />
                      <button
                        v-if="enabledFilters[column.field]"
                        name="Clear Supplier"
                        @click="clearLookupItem(column.field)"
                        class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
                      >
                        <svg
                          class="stroke-current h-5 w-5"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18 6L6 18M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div
                      v-if="filterOptions[column.field].lookup.open"
                      class="z-50 absolute flex flex-col w-96 max-h-35vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 ml-5"
                    >
                      <input
                        :ref="`${column.field}-lookup-field`"
                        type="text"
                        @input="
                          performLookup($event.target.value, column.field)
                        "
                        class="m-2 bge-input bge-input-spacing rounded sticky top-0"
                        placeholder="Enter 3 letters or more"
                      />
                      <div class="flex flex-col overflow-y-auto">
                        <template v-for="result in lookupResults">
                          <button
                            type="button"
                            :key="
                              filterOptions[column.field].lookup.idAccessor(
                                result
                              )
                            "
                            @click="selectLookupItem(result, column.field)"
                            class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                          >
                            <span class="ml-1">{{
                              filterOptions[column.field].lookup.labelAccessor(
                                result
                              )
                            }}</span>
                          </button>
                        </template>
                        <p
                          class="mx-auto py-4 px-8"
                          v-if="lookupResults.length == 0"
                        >
                          No matches for your search
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, i) in data">
            <tr
              @click="rowClicked(i)"
              class="hover:bg-green-500 hover:bg-opacity-25 cursor-pointer group border-b border-secondary border-opacity-25"
              :key="`tr-${i}`"
            >
              <template v-for="(column, e) in columns">
                <td
                  v-if="column.type == 'custom'"
                  class="px-4 py-2"
                  :key="`td-${e}`"
                >
                  <slot :name="column.field" :column="column" :row="row" />
                </td>
                <td v-else class="px-4 py-2" :key="`td-${e}`">
                  {{ formatCell(column, row) }}
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="data.length == 0" class="text-center py-24">
      <p>{{ noDataMessage ? noDataMessage : "There is no data to show." }}</p>
    </div>
    <div
      class="text-sm border-t-2 border-primary w-full flex justify-between px-3 pt-3"
    >
      <p v-if="hasPagination">
        {{ sortOptions.pageSize * currentPage - (sortOptions.pageSize - 1) }} to
        {{
          sortOptions.pageSize * currentPage < totalRecords
            ? sortOptions.pageSize * currentPage
            : totalRecords
        }}
        of
        {{ totalRecords }}
      </p>
      <p v-else>{{ computedTotalRecords }} Total Records</p>
      <div class="flex items-center" v-if="hasPagination">
        <div class="w-full relative">
          <select
            class="bge-input w-full block appearance-none p-1 pr-8 rounded"
            v-model="sortOptions.pageSize"
            @change="onPageSizeChange"
          >
            <option>10</option>
            <option>30</option>
            <option>50</option>
            <option>100</option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div class="flex items-center">
          <button
            @click="changePage(-1)"
            :disabled="currentPage == 1"
            class="mx-1 flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1 disabled:bg-gray-200 disabled:border-gray-400 disabled:shadow-none disabled:cursor-not-allowed"
          >
            <svg class="h-5 w-5 stroke-current" fill="none" viewBox="0 0 24 24">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <p v-if="currentPage && totalPages" class="mx-1 whitespace-no-wrap">
            {{ currentPage }} of {{ totalPages }}
          </p>
          <button
            @click="changePage(1)"
            :disabled="currentPage + 1 > totalPages"
            class="mx-1 flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1 disabled:bg-gray-200 disabled:border-gray-400 disabled:shadow-none disabled:cursor-not-allowed"
          >
            <svg class="h-5 w-5 stroke-current" fill="none" viewBox="0 0 24 24">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Table",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    hasPagination: {
      type: Boolean,
      default: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
    },
    totalPages: {
      type: Number,
    },
    noDataMessage: {
      type: String,
    },
    initialSortOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      sortOptions: {},
      filterOptions: {},
      enabledFilters: {},
      lookupResults: [],
      cachedLookupLabels: {},
    };
  },
  mounted() {
    this.sortOptions = this.initialSortOptions;

    if (this.initialSortOptions.field && !this.hasPagination) {
      this.sortColumn(
        this.columns.find((c) => c.field == this.initialSortOptions.field)
      );
    }

    if (this.data.length > 0) this.buildFilterLists();
  },
  watch: {
    data: function () {
      this.buildFilterLists();
    },
  },
  methods: {
    buildFilterLists: function () {
      const filterableColumns = this.columns.filter((c) => c.filter);

      console.log(
        "[Table] (buildFilterLists) filterableColumns",
        filterableColumns
      );

      this.data.forEach((row) => {
        filterableColumns.forEach((col) => {
          this.filterOptions[col.field] = {
            ...col.filter,
          };
          if (this.filterOptions[col.field].lookup) {
            this.filterOptions[col.field].lookup.open = false;
            if (!this.cachedLookupLabels[col.field]) {
              this.cachedLookupLabels[col.field] = "All";
            }
          }
        });
      });

      console.log(
        "[Table] (buildFilterLists) filterOptions",
        this.filterOptions
      );
    },
    formatCell: function (column, row) {
      switch (column.type) {
        case "date":
          return this.$moment.unix(column.selector(row)).format("DD/MM/YYYY");
        case "name":
          return `${row.first_name} ${row.last_name}`;
        case "address":
          let address1 = row.address1 ? `${row.address1}, ` : "";
          let address2 = row.address2 ? `${row.address2}, ` : "";
          let address3 = row.address3 ? `${row.address3}, ` : "";
          let city = row.city ? `${row.city}, ` : "";
          let postcode = row.postcode ? `${row.postcode}` : "";

          return `${address1}${address2}${address3}${city}${postcode}`;
        default:
          if (typeof column.selector == "function") {
            return column.selector(row);
          }
          return row[column.field];
      }
    },
    rowClicked: function (i) {
      this.$emit("onRowClicked", this.data[i]);
    },
    changePage: function (direction) {
      this.$emit("onPageChanged", direction);
    },
    sortColumn: function (column) {
      if (column.sortable == false) return;

      if (this.sortOptions.field == column.field) {
        this.sortOptions.isAsc = !this.sortOptions.isAsc;
      } else {
        this.sortOptions.field = column.field;
        this.sortOptions.isAsc = true;
      }

      if (this.hasPagination) {
        this.$emit("onSortChanged", this.sortOptions);
      } else {
        // * HANDLE PAGINATION CLIENT SIDE
        this.data.sort((a, b) => {
          // const isDesc = currentSort && currentSort[0] === "-" ? true : false;
          // const currentSortName =
          //   currentSort && currentSort[0] === "-"
          //     ? currentSort.substring(1)
          //     : currentSort;
          // console.log("column", column.field);
          // console.log(a, b);

          const aSelector =
            typeof column.selector == "function"
              ? column.selector(a)
              : column.field;
          const bSelector =
            typeof column.selector == "function"
              ? column.selector(b)
              : column.field;

          console.log("A & B selector", aSelector, bSelector);

          // const aSelector = columnInData?.sortSelector
          //   ? column?.sortSelector(a)
          //   : typeof column?.selector == "function"
          //   ? column?.selector(a)
          //   : column?.selector;
          // const bSelector = column?.sortSelector
          //   ? column?.sortSelector(b)
          //   : typeof column?.selector == "function"
          //   ? column?.selector(b)
          //   : column?.selector;
          if (this.sortOptions.isAsc) {
            return aSelector.toString().localeCompare(bSelector);
          } else {
            return bSelector.toString().localeCompare(aSelector);
          }
        });
      }
    },
    filterChanged: function ({ column, value }) {
      console.log("[Table] (filterChanged)", column, value);
      this.enabledFilters[column] = value;
      if (!value) {
        delete this.enabledFilters[column];
      }
      this.$emit("onFilterChanged", this.enabledFilters);
    },
    onPageSizeChange: function () {
      this.$emit("onSortChanged", this.sortOptions);
    },
    openLookup: function (column) {
      this.lookupResults = [];
      this.filterOptions[column].lookup.open = true;
      this.$forceUpdate();
      this.$nextTick(function () {
        this.$refs[`${column}-lookup-field`][0].focus();
      });
    },
    closeLookup: function (column) {
      this.filterOptions[column].lookup.open = false;
      this.$forceUpdate();
    },
    selectLookupItem: function (item, column) {
      const id = this.filterOptions[column].lookup.idAccessor(item);
      const label = this.filterOptions[column].lookup.labelAccessor(item);

      this.enabledFilters[column] = id;

      this.cachedLookupLabels[column] = label;

      this.$emit("onFilterChanged", this.enabledFilters);

      console.log(this.enabledFilters);

      this.closeLookup(column);
    },
    clearLookupItem: function (column) {
      delete this.enabledFilters[column];
      delete this.cachedLookupLabels[column];

      this.$emit("onFilterChanged", this.enabledFilters);
    },
    performLookup: _.debounce(async function (searchTerm, columnField) {
      this.lookupResults = [];
      if (
        searchTerm != "" &&
        searchTerm.length >= 3 &&
        this.filterOptions[columnField].lookup
      ) {
        let results =
          await this.filterOptions[columnField].lookup.searchFn(searchTerm);
        this.lookupResults = results.data;
      }
    }, 500),
  },

  computed: {
    computedTotalRecords() {
      if (this.totalRecords !== null && this.totalRecords !== undefined) {
        return this.totalRecords;
      }
      return this.data ? this.data.length : 0;
    },
  },
};
</script>
