<template>
  <form @submit.prevent="onCreateActivation" class="relative">
    <div
      v-show="createActivationViewState == 'Loading'"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <!-- Customer -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Customer
        <span class="text-red-500">*</span>
      </label>
      <div
        class="fixed inset-0 overflow-hidden z-10"
        v-if="contactSelectOpen"
        @click="contactSelectOpen = false"
      ></div>
      <div class="relative mx-3">
        <div class="relative -mx-3">
          <input
            readonly
            @click="openSearchContact"
            v-model="contactName"
            class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
            type="text"
          />
          <button
            v-if="contactName"
            name="Clear Customer"
            @click="clearContact"
            class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div
          v-if="contactSelectOpen"
          class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
        >
          <div class="relative flex flex-col">
            <input
              ref="tbSupplierSearch"
              type="text"
              v-model="contactSearchTerm"
              @keydown="searchContact"
              class="bge-input bge-input-spacing rounded"
              placeholder="Customer name (enter 3 letters or more to search)"
            />
            <div class="flex flex-col overflow-y-scroll bg-white mt-2">
              <template v-for="result in contactResults">
                <button
                  type="button"
                  :key="result.contact_id"
                  @click="selectContact(result)"
                  class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                >
                  <span class="ml-1">{{ result.name }}</span>
                </button>
              </template>
              <p class="mx-auto py-4 px-8" v-if="contactResults == ''">
                No customers match your search
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Customer -->

    <!-- Client Type -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Client Type<span class="text-red-500">*</span></label
      >
      <div class="relative">
        <select
          class="bge-input bge-select rounded"
          required
          v-model="activationRequest.client_type"
        >
          <option selected value="SW900V4">SW900V4 (Ivy)</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- /Client Type -->

    <!-- Equipment -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Equipment<span class="text-red-500">*</span></label
      >
      <MultiSelect
        v-if="options"
        :options="options"
        v-model="activationRequest.activated_product_codes"
      />
      <!-- <div class="relative">
        <select
          class="bge-input bge-select rounded"
          required
          v-model="activationRequest.client_type"
        >
          <option selected value="SW900V4">SW900V4 (Ivy)</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div> -->
    </div>
    <!-- /Equipment -->

    <!-- PC Name -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >PC Name<span class="text-red-500">*</span></label
      >
      <input
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="e.g. Emissions PC"
        required
        v-model="activationRequest.client_name"
      />
    </div>
    <!-- /PC Name -->

    <!-- VTS Number -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >VTS Number<span class="text-red-500">*</span></label
      >
      <input
        class="w-full bge-input bge-input-spacing rounded uppercase"
        required
        v-model="activationRequest.vts_number"
      />
    </div>
    <!-- /VTS Number -->

    <!-- Notes -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm">Activation Notes</label>
      <input
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        v-model="activationRequest.notes"
      />
    </div>
    <!-- /Notes -->

    <!-- Address Line 1 -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Address Line 1<span class="text-red-500">*</span></label
      >
      <input
        disabled
        type="text"
        class="w-full bge-input bge-input-spacing rounded bge-input-disabled"
        required
        v-model="activationRequest.address1"
      />
    </div>
    <!-- /Address Line 1 -->

    <!-- Address Line 2 -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Address Line 2<span class="text-red-500">*</span></label
      >
      <input
        disabled
        type="text"
        class="w-full bge-input bge-input-spacing rounded bge-input-disabled"
        required
        v-model="activationRequest.address2"
      />
    </div>
    <!-- /Address Line 2 -->

    <!-- City -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >City<span class="text-red-500">*</span></label
      >
      <input
        disabled
        type="text"
        class="w-full bge-input bge-input-spacing rounded bge-input-disabled"
        required
        v-model="activationRequest.city"
      />
    </div>
    <!-- /City -->

    <!-- Country -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Country<span class="text-red-500">*</span></label
      >
      <input
        disabled
        type="text"
        class="w-full bge-input bge-input-spacing rounded bge-input-disabled"
        required
        v-model="activationRequest.country"
      />
    </div>
    <!-- /Country -->

    <!-- Postcode -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Postcode<span class="text-red-500">*</span></label
      >
      <input
        disabled
        type="text"
        class="w-full bge-input bge-input-spacing rounded bge-input-disabled"
        required
        v-model="activationRequest.postcode"
      />
    </div>
    <!-- /Postcode -->

    <div class="flex justify-end mt-5">
      <button
        type="submit"
        class="relative overflow-hidden bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <div class="flex items-center">
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
          >
            <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
          </svg>
          <span>Create Activation</span>
        </div>
        <div
          class="absolute inset-0 flex justify-center items-center bg-blue-200 hover:bg-blue-300"
          v-if="createActivationViewState == 'Loading'"
        >
          <Spinner class="" :color="'text-black'" :size="6" />
        </div>
      </button>
    </div>
    <!-- <div class="relative flex justify-end mt-5">
      <div v-show="!isBusy" class="opacity-50 bg-white absolute inset-0"></div>
      <button
        :disabled="isBusy"
        type="submit"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </div> -->
  </form>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const Datepicker = () => import("vuejs-datepicker");
import _ from "lodash";
import MultiSelect from "../../MultiSelect.vue";

export default {
  name: "CreateIvyActivation",
  components: {
    Spinner,
    Toggle,
    Datepicker,
    MultiSelect,
  },
  data() {
    return {
      options: null,
      contactName: "",
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
      createActivationViewState: "Idle",
      activationRequest: {
        client_type: "SW900V4",
        activated_product_codes: [],
        address1: "",
        address2: "",
        city: "",
        country: "",
        postcode: "",
        created_by_boston_user_id: "",
        created_by_boston_user: "",
        notes: "",
      },
    };
  },
  async mounted() {
    this.createActivationViewState = "Loading";
    const data = await this.ProductService.getProductCodeDataFromConfig();
    let newOptions = [];
    Object.entries(data).map(([key, value]) => {
      newOptions.push({
        value: key,
        text: value.title,
      });
    });
    this.options = newOptions;
    this.createActivationViewState = "Idle";

    const userData = this.AuthService.getUserInfo();

    this.activationRequest.created_by_boston_user_id = userData.sub;
    this.activationRequest.created_by_boston_user = `${userData.name} (${userData.email})`;
  },
  methods: {
    async onCreateActivation() {
      try {
        this.createActivationViewState = "Loading";
        //  Make the request
        this.activationRequest.vts_number =
          this.activationRequest.vts_number.toUpperCase();
        let activationResponse = await this.iVClientService.createIvyActivation(
          this.activationRequest
        );

        this.$emit("onActivationComplete", activationResponse);

        this.contactName = "";
        this.activationRequest = {
          client_type: "SW900V4",
          address1: "",
          address2: "",
          city: "",
          country: "",
          postcode: "",
          activated_product_codes: [],
          created_by_boston_user_id: "",
          created_by_boston_user: "",
          notes: "",
        };

        // Set highlightedCodeId as ID for the sucessful request... GO TO markRowAsSelected()

        // This will highlight the code just created to show them the result.

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              { props: { title: "Success", close: onClose } },
              "Ivy Code Generated"
            );
          },
          { position: "top-right" }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              { props: { type: "danger", title: "Error", close: onClose } },
              `Error while generating Ivy code: ${error.message}`
            );
          },
          { position: "top-right" }
        );
      }
      this.createActivationViewState = "Idle";
    },
    openSearchContact: function () {
      this.contactSearchTerm = "";
      this.contactResults = [];
      this.contactSelectOpen = true;
      this.$nextTick(function () {
        this.$refs.tbSupplierSearch.focus();
      });
    },
    searchContact: _.debounce(async function () {
      this.contactResults = [];
      if (this.contactSearchTerm != "" && this.contactSearchTerm.length >= 3) {
        let results = await this.ContactService.searchContact(
          this.contactSearchTerm
        );
        this.contactResults = results.data;
        console.log(results.data);
      }
    }, 500),
    selectContact: function (contact) {
      this.activationRequest.contact_id = contact.contact_id;
      this.contactName = contact.name;
      this.contactSelectOpen = false;
      this.activationRequest.vts_number = contact.vts_number;
      this.getContactAddresses();
    },
    clearContact: function () {
      this.activationRequest.contact_id = "";
      this.contactName = "";
      this.$forceUpdate();
    },
    getContactAddresses: async function () {
      if (!this.activationRequest.contact_id) return;
      try {
        let contact = await this.ContactService.getContactById(
          this.activationRequest.contact_id
        );
        let fieldsToUpdate = [
          "address1",
          "address2",
          "city",
          "country",
          "postcode",
        ];
        fieldsToUpdate.forEach((field) => {
          console.log(contact.addresses[0][field]);
          this.activationRequest[field] = contact.addresses[0][field];
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
