<template>
  <div>
    <transition name="fade">
      <div v-if="showing" class="inset-0 z-40 absolute bg-black bg-opacity-25"></div>
    </transition>
    <transition name="slide">
      <div v-if="showing" class="absolute inset-0 z-40">
        <div class="w-full md:w-1/2 lg:w-1/3 fixed h-screen bg-white shadow-2xl z-40 right-0 px-6 pt-6 flex flex-col">
          <div class="w-full flex items-center">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              {{ title }}
            </h3>
            <hr class="flex-1 border-primary ml-4 mr-10"/>
            <button
                @click.prevent="close"
                class="flex items-center bg-white text-secondary p-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
            >
              <svg class="stroke-current h-6 w-6" fill="none" stroke-width="2" stroke-linecap="round"
                   stroke-linejoin="round" viewBox="0 0 24 24">
                <path d="M18 6L6 18M6 6l12 12"/>
              </svg>
            </button>
          </div>
          <div class="flex-1 flex mt-5 pb-5 pr-2 flex-col overflow-y-scroll max-h-full">
            <!-- Use: pr-3 -mr-6 if we use the orange nav again -->
            <slot/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
  },
  //   watch: {
  //     showing(value) {
  //       if (value) {
  //         return document.querySelector('body').classList.add('overflow-hidden');
  //       }

  //       document.querySelector('body').classList.remove('overflow-hidden');
  //     }
  //   },
  methods: {
    close() {

      this.$emit("close");
    },
  },
};
</script>
