<template>
  <div class="mt-1 grid grid-cols-3 gap-2">
    <div
      v-for="option in formattedOptions"
      class="relative flex items-center"
      :key="option.value"
    >
      <div class="flex items-center">
        <input
          :id="option.value"
          aria-describedby="comments-description"
          :name="option.value"
          type="checkbox"
          :checked="option.checked"
          @change="onChange(option.value)"
          class="h-5 w-5 form-checkbox focus:shadow-none border-gray-400 bg-gray-100 text-primary focus:border-primary"
        />
      </div>
      <div class="ml-2 text-sm">
        <label :for="option.value">{{ option.text }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    event: "change",
  },
  props: {
    value: {
      required: true,
    },
    options: {
      required: true,
    },
  },
  data() {
    return {
      formattedOptions: {},
    };
  },
  mounted() {
    this.options.map((op) => {
      this.formattedOptions = {
        ...this.formattedOptions,
        [op.value]: {
          ...op,
          checked: this.value.includes(op.value) ? true : false,
        },
      };
    });
  },
  methods: {
    onChange: function (val) {
      const item = this.formattedOptions[val];
      item.checked = !item.checked;

      var array = [];
      for (const [key, value] of Object.entries(this.formattedOptions)) {
        if (value.checked) {
          array.push(key);
        }
      }

      this.$emit("change", array);
    },
  },
};
</script>
